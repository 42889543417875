import Vue from "vue";

const state = Vue.observable({
  showMenu: false,
});

export const getters = {
  showMenu: () => state.showMenu,
};

export const mutations = {
  setShowMenu(val: boolean) {
    state.showMenu = val;
  },
};

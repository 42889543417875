

















import Vue from "vue";
import { getters, mutations } from "@/store.ts";

export default Vue.extend({
  name: "AppBtn",
  computed: {
    ...getters
  },
  methods: {
    ...mutations
  }
});






































































































import Vue from "vue";
import { getters, mutations } from "@/store.ts";
import { MenuItem } from "./types";

interface AppData extends Vue {
  direction: Direction;
  currentRootId?: string;
  currentRoot?: MenuItem;
  currentParent?: MenuItem;
  menuRoot: MenuItem;
}

const enum Direction {
  NEXT = "next",
  PREV = "prev"
}

export default Vue.extend({
  name: "App",
  data() {
    const selectedItemHasChildren = this.menuItems.some(
      x => x.parentId == this.selectedItemId
    );
    return {
      placeHolderTopContent: this.placeholderTopContent,
      placeHolderBottomContent: this.placeholderBottomContent,
      direction: Direction.NEXT,
      currentRootId: selectedItemHasChildren
        ? this.selectedItemId
        : this.menuItems.find(x => x.id === this.selectedItemId)?.parentId ??
          null,
      menuRoot: {
        id: null,
        title: this.menuTitle,
        parentId: null
      }
    };
  },
  methods: {
    ...mutations,
    prev(this: AppData) {
      this.direction = Direction.PREV;
      this.$nextTick(() => (this.currentRootId = this.currentParent?.id));
    },
    next(this: AppData, nextRootId: string) {
      this.direction = Direction.NEXT;
      this.$nextTick(() => (this.currentRootId = nextRootId));
    }
  },
  computed: {
    ...getters,
    currentChildren(this: AppData) {
      return (
        this.menuItems.filter(x => x.parentId === this.currentRootId) ??
        this.menuItems
      );
    },
    currentRoot(this: AppData) {
      return (
        this.menuItems.find(x => x.id === this.currentRootId) ?? this.menuRoot
      );
    },
    currentParent(this: AppData) {
      return (
        this.menuItems.find(x => x.id === this.currentRoot?.parentId) ??
        this.menuRoot
      );
    }
  }
});

declare const menuTitle: string;
declare const menuImgSrc: string;
declare const menuHiddenBreakpoint: string;
declare const selectedItemId: string;
declare const placeholderTopContent: string;
declare const placeholderBottomContent: string;
declare const menuItems: object[];
import Vue from "vue";
import AppBtn from "./AppBtn.vue";
import App from "./App.vue";

Vue.config.productionTip = false;

Vue.prototype.menuTitle = menuTitle;
Vue.prototype.menuImgSrc = menuImgSrc;
Vue.prototype.menuHiddenBreakpoint = menuHiddenBreakpoint;
Vue.prototype.selectedItemId = selectedItemId;
Vue.prototype.menuItems = menuItems;
Vue.prototype.placeholderTopContent = placeholderTopContent;
Vue.prototype.placeholderBottomContent = placeholderBottomContent;

if (document.getElementById("mb-menu-btn")) {
  new Vue({
    render: (h) => h(AppBtn),
  }).$mount("#mb-menu-btn");
}

new Vue({
  render: (h) => h(App),
}).$mount("#mb-menu");
